<template>
  <div class="bookable-form">
    <div ref="form" class="bookable-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Type</label>
          <SelectBookableType
            flat
            solo
            required
            class="mt-2"
            v-model="form.type"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('type')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Title"
            v-model="form.title"
            :error-messages="form.$getError('title')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Tagline</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Tagline"
            v-model="form.tagline"
            :error-messages="form.$getError('tagline')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Availability</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Availability"
            v-model="form.availability"
            :error-messages="form.$getError('availability')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Description</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="Description"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Booking Option</label>
          <SelectBookingOption
            flat
            solo
            required
            class="mt-2"
            v-model="form.booking_option"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('booking_option')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <template v-if="isExternalBooking">
            <label class="text-field-label">Booking External Link</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              placeholder="External Link"
              v-model="form.external_booking_link"
              :error-messages="form.$getError('external_booking_link')"
              :loading="form.$busy"
              :disabled="form.$busy"
            ></v-text-field>
          </template>
          <template v-if="isRequestBooking || isBookingCalendar">
            <label class="text-field-label">Booking Request Emails</label>
            <NotificationEmail
              ref="notificationEmail"
              v-model="form.notification_emails"
              flat
              solo
              class="mt-2 mb-2"
              hide-details="auto"
              :error-messages="form.$getError('notification_emails')"
              :loading="form.$busy"
              :disabled="form.$busy"
              :placeholder="
                isRequestBooking
                  ? 'Booking request email'
                  : 'Booking request Notification'
              "
            />
            <p>Please use comma (,) as a separator for email.</p>
          </template>
          <BookableBookingScheduleTypeForm
            :form="form"
            v-if="form.booking_option === bookingCalendarType"
          />

          <v-row dense>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_active"
                hide-details="auto"
                label="Set as active"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_featured"
                hide-details="auto"
                label="Set as featured"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6" v-if="isRequestBooking">
              <v-checkbox
                v-model="form.has_end_time"
                hide-details="auto"
                label="Has end time"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-if="form.booking_option === bookingCalendarType"
                v-model="form.approval_required"
                hide-details="auto"
                label="Approval Required"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Banner Image</label>
          <BookableFileUpload
            ref="imagesUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('images')"
            :for-update="isUpdate"
            @updated="form.$clearError('images')"
            :hasPermissions="hasPermissions"
          />
          <p class="hint">Ideal image size 685px x 600px</p>

          <template v-if="showBookingTimeSlots">
            <hr class="my-7" />
            <BookingScheduleBlockTimeSlot
              :form="form"
              :hasPermissions="hasPermissions"
            />
          </template>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              @click="submit"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <template v-if="showError">
      <v-dialog
        :value="showError"
        @click:outside="close"
        @keydown.esc="close"
        max-width="520"
      >
        <v-card>
          <v-card-title class="headline"> Warning </v-card-title>
          <v-card-text>
            <strong>{{ errorMessage }}</strong>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn
                text
                color="green"
                class="confirm-modal__confirm"
                @click="close"
                >Okay</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectBuilding from '@/components/fields/SelectBuilding'
import SelectBookableType from '@/components/fields/SelectBookableType'
import SelectBookingOption from '@/components/fields/SelectBookingOption'
import NotificationEmail from '@/components/fields/NotificationEmail'
import BookableFileUpload from '@/views/Home/Bookables/components/BookableFileUpload'
import { isValidLink } from '@/utils/helpers'
import {
  EXTERNAL_BOOKING,
  BOOKING_CALENDAR,
  REQUEST_BOOKING,
} from '@/utils/enums/BookingOption'
import BookableBookingScheduleTypeForm from './BookableBookingScheduleTypeForm.vue'
import BookingScheduleBlockTimeSlot from './BookingScheduleBlockTimeSlot.vue'
import { checkTimeDifferenceDivisible } from '../../../../utils/date'
import { TIME_SLOT_INCREMENTS_ERROR } from '../../../../utils/enums/MessageAlert'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectBuilding,
    SelectBookableType,
    SelectBookingOption,
    NotificationEmail,
    BookableFileUpload,
    BookableBookingScheduleTypeForm,
    BookingScheduleBlockTimeSlot,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    bookable: {
      type: Object,
      default: () => {
        return null
      },
    },
    showBookingTimeSlots: {
      type: Boolean,
      default: () => {
        return true
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        type: '',
        title: '',
        tagline: '',
        description: '',
        availability: '',
        booking_option: '',
        external_booking_link: '',
        has_end_time: false,
        notification_emails: [],
        is_featured: '',
        is_active: '',
        images: [],
        time_slot_increment: 0,
        is_multiple_time_slot: false,
        schedule_settings: {},
        approval_required: false,
      }),
      showError: false,
      errorMessage: '',
    }
  },

  computed: {
    ...mapState({
      createdBookable: (state) => state.bookable.bookableDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate
        ? 'Service & Space Information'
        : 'Create Service & Space'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateBookable : this.createBookable
    },

    isExternalBooking() {
      return this.form.booking_option === EXTERNAL_BOOKING
    },

    isRequestBooking() {
      return this.form.booking_option === REQUEST_BOOKING
    },

    isBookingCalendar() {
      return this.form.booking_option === BOOKING_CALENDAR
    },

    isUpdate() {
      return !!this.bookable
    },

    bookingCalendarType() {
      return BOOKING_CALENDAR
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.BOOKABLES_UPDATE],
        this.permissions
      )
    },
  },

  watch: {
    bookable(newValue, oldValue) {
      this.initForm()
    },
    form(newValue) {
      console.log('form', newValue)
    },
  },

  methods: {
    ...mapActions({
      createBookable: 'bookable/createBookable',
      updateBookable: 'bookable/updateBookable',
    }),

    initForm() {
      if (this.bookable) {
        this.form.building_id = this.bookable.building_id
        this.form.type = this.bookable.type
        this.form.title = this.bookable.title
        this.form.tagline = this.bookable.tagline
        this.form.description = this.bookable.description
        this.form.availability = this.bookable.availability
        this.form.booking_option = this.bookable.booking_option
        this.form.external_booking_link = this.bookable.external_booking_link
        this.form.has_end_time = this.bookable.has_end_time
        this.form.notification_emails = this.bookable.notification_emails
        this.form.is_featured = this.bookable.is_featured
        this.form.is_active = this.bookable.is_active
        this.form.time_slot_increment = this.bookable.time_slot_increment
        this.form.is_multiple_time_slot = this.bookable.is_multiple_time_slot
        this.form.schedule_settings = this.bookable.schedule_settings
        this.form.approval_required = this.bookable.approval_required
      }
    },

    setBookableFormSnackbarVisibility() {
      this.showError = !this.showError
      this.errorMessage = ''
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return
      if (
        checkTimeDifferenceDivisible(
          this.getFormData().schedule_settings,
          this.getFormData().time_slot_increment
        ) ||
        checkTimeDifferenceDivisible(
          this.form.schedule_settings,
          this.form.time_slot_increment
        )
      ) {
        this.showSnackbar(TIME_SLOT_INCREMENTS_ERROR, false)
        return
      }
      this.form.$busy = true
      this.form.$clearErrors()

      /**
       * v-comboxbox value not getting updated when its still on focus
       * and submit was run
       * https://github.com/vuetifyjs/vuetify/issues/3424
       */
      if (this.$refs.notificationEmail) {
        await this.$refs.notificationEmail.refresh()
      }

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then((res) => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Service & Space details successfully updated!')
            if (res.change_booking_ids.length > 0) {
              this.showError = true
              if (res.change_booking_ids.length > 1) {
                this.errorMessage = `There are ${res.change_booking_ids.length} bookings
                that are no longer in the opening window.`
              } else {
                this.errorMessage = `There is ${res.change_booking_ids.length} booking
                that are no longer in the opening window.`
              }
            }
          } else {
            this.showSnackbar('Service & Space successfully created!')
            this.$router.push({
              name: 'bookables',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (
        this.isExternalBooking &&
        !isValidLink(this.form.external_booking_link)
      ) {
        this.form.$setError('external_booking_link', 'Must be a valid URL link')
      }

      // if (!this.form.tagline) {
      //   this.form.$setError('tagline', 'Tagline is required')
      // }

      // if (!this.isUpdate) {
      //   if (!this.$refs.imagesUploader.hasData()) {
      //     this.form.$setError('images', 'Must upload at least 1 image')
      //   }
      // }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      delete form.images

      form.type = form.type ? form.type.toLowerCase() : null

      if (this.isUpdate) {
        form.id = this.bookable.id
        // if (form.time_slots && !Array.isArray(form.time_slots)) {
        //   form.time_slots = [form.time_slots]
        // }
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* Pass arrays properly */
            case 'notification_emails':
              if (this.$refs.notificationEmail) {
                this.$refs.notificationEmail.getEmails(formData)
              }
              break
            // case 'time_slot_increment':
            //   let timeSlots = form[field]
            //   if (!Array.isArray(timeSlots)) {
            //     timeSlots = [timeSlots]
            //   }
            //   timeSlots.forEach((slot) => {
            //     formData.append('time_slots[]', slot)
            //   })
            //   break
            case 'schedule_settings':
              if (form[field]) {
                formData.append(field, JSON.stringify(form[field]))
              } else {
                formData.append(field, null)
              }
              break
            /* ...append normally everything else */
            default:
              formData.append(field, form[field])
              break
          }
        }

        this.$refs.imagesUploader.getImages(formData)

        return formData
      }
    },

    close() {
      this.showError = false
      this.errorMessage = ''
    },
  },
}
</script>
<style lang="scss">
.bookable-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }

  .bookable-form-snackbar {
    bottom: 5rem;
  }
}
</style>
