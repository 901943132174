<template>
  <v-select
    class="select-time-increment-option"
    :value="selectedItem"
    :items="items"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
    @input="onChange"
    :multiple="multiple"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Building
 * ==================================================================================
 **/

import { difference, find, map } from 'lodash'
import { TIME_SLOT_INCREMENTS } from '@/utils/enums/BookingOption'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
      validator: (value) => {
        if (Array.isArray(value)) {
          const timeSlots = map(TIME_SLOT_INCREMENTS, (item) => item.value)
          return difference(value, timeSlots).length === 0
        } else {
          return find(TIME_SLOT_INCREMENTS, (item) => item.value === value)
        }
      },
    },

    placeholder: {
      type: String,
      default: 'Select option',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    items() {
      return [...TIME_SLOT_INCREMENTS]
    },
    selectedItem() {
      if (this.multiple) {
        return Array.isArray(this.value) ? this.value : [this.value]
      }

      return Array.isArray(this.value) ? this.value[0] : this.value
    },
  },

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
