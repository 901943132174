<template>
  <v-row class="pb-5 bookable-booking-schedule-type">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Time Slot Increment</label>
          <SelectTimeSlotIncrement
            flat
            solo
            required
            class="mt-2"
            v-model="form.time_slot_increment"
            :loading="form.$busy"
            :disabled="form.$busy"
            :multiple="false"
          />
        </v-col>
        <v-col cols="12" md="6">
          <label class="text-field-label">&nbsp;</label>
          <v-checkbox
            v-model="form.is_multiple_time_slot"
            hide-details="auto"
            label="Can select multiple"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="2" md="2"></v-col>
        <v-col cols="5" md="4">
          <label class="text-field-label">Start</label>
        </v-col>
        <v-col cols="5" md="4">
          <label class="text-field-label">End</label>
        </v-col>
      </v-row>
      <template>
        <v-row dense v-for="value in getSettings" :key="value">
          <v-col cols="2" md="2" class="checkbox-wrapper">
            <v-checkbox
              v-model="scheduleSettings[value].open"
              hide-details="auto"
              :label="showLabel(value)"
              @change="setFormData"
              class="checkbox-wrapper__time-slot"
            ></v-checkbox>
          </v-col>
          <v-col cols="5" md="4">
            <DatetimePicker
              flat
              solo
              v-model="scheduleSettings[value].start"
              :disabled="!scheduleSettings[value].open"
              :minuteStep="5"
              :style="{ maxWidth: 100 }"
              type="time"
              placeholder="Start"
              hide-details
              @input="setFormData"
              valueZone="UTC"
            />
          </v-col>
          <v-col cols="5" md="4">
            <DatetimePicker
              flat
              solo
              v-model="scheduleSettings[value].end"
              :disabled="!scheduleSettings[value].open"
              :minuteStep="5"
              type="time"
              placeholder="End"
              hide-details
              @input="setFormData"
              valueZone="UTC"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Booking Schedule Type Form
 * ==================================================================================
 **/
import { get, upperFirst, map } from 'lodash'
import DatetimePicker from '@/components/fields/DatetimePicker'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import {
  DAY_OF_WEEKS,
  DEFAULT_WORKING_HOURS,
} from '@/utils/enums/BookingOption'
import { getDefaultDatetime } from '@/utils/date'
import SelectTimeSlotIncrement from '@/components/fields/SelectTimeSlotIncrement'

export default {
  components: {
    SelectTimeSlotIncrement,
    DatetimePicker,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    form: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      scheduleSettings: {
        mon: {
          start: '',
          end: '',
          open: false,
        },
      },
    }
  },

  created() {
    const settings = get(this.form, 'schedule_settings', {})
    this.scheduleSettingInit(settings)
  },

  computed: {
    getSettings() {
      return Object.keys(this.scheduleSettings)
    },
  },

  watch: {
    form(newValue, oldValue) {
      this.scheduleSettingInit(get(newValue, 'schedule_settings', {}))
    },
  },

  methods: {
    scheduleSettingInit(data) {
      let settings = {}
      map(DAY_OF_WEEKS, (item) => {
        Object.assign(settings, {
          [item.value]: {
            start: get(
              data,
              item.value + '.start',
              getDefaultDatetime(DEFAULT_WORKING_HOURS.start)
            ),
            end: get(
              data,
              item.value + '.end',
              getDefaultDatetime(DEFAULT_WORKING_HOURS.end)
            ),
            open: get(data, item.value + '.open', false),
          },
        })
      })
      this.scheduleSettings = { ...settings }
    },

    showLabel(text) {
      return upperFirst(text)
    },

    setFormData() {
      this.form.schedule_settings = this.scheduleSettings
    },
    // emitBookingSchedule() {
    //   this.$emit('update-booking-schedule', this.scheduleSettings)
    // },
  },
}
</script>
<style lang="scss">
.bookable-booking-schedule-type .datetime-picker__date {
  max-width: 100px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;

  &__time-slot {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
