<template>
  <v-dialog
    :value="open"
    @click:outside="$emit('cancel')"
    @input="$emit('input')"
  >
    <v-card class="pa-9">
      <v-card-title class="title pa-0 font-weight-bold">
        {{ header }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="bookings"
        :server-items-length="bookings.length"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
      >
        <template v-slot:item.user="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:item.bookable="{ item }">
          {{ item.serviceTitle }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.serviceType }}
        </template>
        <template v-slot:item.datetime_start="{ item }">
          {{ item.bookingDate }}
        </template>
        <template v-slot:item.property="{ item }">
          {{ item.user?.property?.name }}
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import Status from '@/components/common/Status'

export default {
  components: {
    Status,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    bookings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selected: null,
      headers: [
        {
          text: 'User',
          value: 'user',
          width: '13%',
        },
        {
          text: 'Request',
          value: 'bookable',
          width: '14%',
        },
        {
          text: 'Type',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Requested Date',
          value: 'booking_date',
          width: '20%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
      ],
      list: [],
    }
  },

  computed: {
    header() {
      return 'Booking List'
    },
  },

  methods: {
    editItem(item) {
      this.$router.push({
        name: 'booking.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
