<template>
  <div :style="{ width: '130%' }">
    <div class="d-flex mb-2" v-if="hasPermissions">
      <label class="text-field-label">Block Time Slots</label>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="35px"
        @click="createItem()"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Block
      </v-btn>
    </div>
    <div
      class="mx-4"
      id="block-time-slot-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="blockItems"
        :options.sync="options"
        :loading="loading"
        :items-per-page="10"
        :server-items-length="blockTimeSlotMeta.total"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        @update:options="fetchBlockTimeSlotList"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:item.start="{ item }">
          {{ formatTime(item.start_time) }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ formatTime(item.end_time) }}
        </template>
        <template v-slot:item.actions="{ item }" v-if="hasPermissions">
          <v-icon class="me-2" size="default" @click="editItem(item)">{{
            icons.edit
          }}</v-icon>
          <v-icon size="default" @click="deleteItem(item)">{{
            icons.delete
          }}</v-icon>
        </template>
      </v-data-table>
      <v-btn
        variant="text"
        class="show-hide-slots-btn"
        @click="toggleShowHidePastSlots"
        :loading="loading"
      >
        <v-icon>{{ showAllSlots ? icons.eyesOn : icons.eyesOff }}</v-icon>
        <span class="primary--text ml-2">Show/Hide Past Slots</span>
      </v-btn>
    </div>

    <v-dialog v-model="dialogBlockSlotTime" max-width="740px">
      <v-form
        ref="formCreateBlockTimeSlot"
        class="bookable-block-time-slot-form__form"
        @submit.prevent="submitCreate"
      >
        <input type="hidden" :value="formBlockTimeSlot.id" name="id" />
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >{{ formBlockTimeSlot.id ? 'Edit' : 'New' }} Block</span
            >
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="6" sm="6" md="4">
                  <v-text-field
                    flat
                    solo
                    placeholder="Description"
                    v-model="formBlockTimeSlot.description"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    :error-messages="formBlockTimeSlot.$getError('description')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="4">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.date"
                    type="date"
                    placeholder="Date"
                    hide-details
                    showCalendarIcon
                    dateFormat="YYYY-MM-DD"
                    :valueZone="timeZone"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.start"
                    type="time"
                    placeholder="Start"
                    hide-details
                    :valueZone="timeZone"
                    :minuteStep="5"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.end"
                    type="time"
                    placeholder="End"
                    hide-details
                    :valueZone="timeZone"
                    :minuteStep="5"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialog()"
            >
              CANCEL
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              name="submitCreateBlockSlotItem"
              :loading="formBlockTimeSlot.$busy"
              :disabled="
                !formBlockTimeSlot.date ||
                !formBlockTimeSlot.start ||
                !formBlockTimeSlot.end
              "
              @click="submitCreate"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBlockSlotTime" max-width="600px">
      <v-form
        ref="formDeleteBlockTimeSlot"
        class="faq-form__form"
        @submit.prevent="submitDeleteBlockTimeSlot"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this block time slot?</span
            >
          </v-card-title>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              NO
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitDeleteBlockTimeSlot"
              name="submitDeleteBlockTimeSlot"
            >
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="showError" :color="'red'" :timeout="-1" right>
      {{ errorMessage }}
      <v-btn text @click.prevent="setSnackbarVisibility(false)"
        >View Details</v-btn
      >
    </v-snackbar>
    <template v-if="openBookingList">
      <BookableBookingListModal
        :open="openBookingList"
        :bookings="bookingList"
        @cancel="toggleBookingListModal"
        @input="toggleBookingListModal"
      />
    </template>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Booking Schedule Type Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { mdiPencil, mdiDelete, mdiPlus, mdiEyeOff, mdiEye } from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import { dateOnlyFormat, timeOnlyFormat, dateFormat } from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { changeDateInString } from '../../../../utils/date'
import BookableBookingListModal from './BookableBookingListModal'
import Booking from '@/models/Booking'

export default {
  components: {
    BookableBookingListModal,
    DatetimePicker,
  },

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  props: {
    form: {
      type: Object,
      default: () => {
        return null
      },
    },
    hasPermissions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const initForm = {
      id: '',
      description: '',
      date: '',
      start: '',
      end: '',
    }

    return {
      defaultFilter: 'name',
      sortOrder: false,
      icons: {
        edit: mdiPencil,
        delete: mdiDelete,
        add: mdiPlus,
        eyesOff: mdiEyeOff,
        eyesOn: mdiEye,
      },
      headers: [
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'Start',
          value: 'start',
        },
        {
          text: 'End',
          value: 'end',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      actions: [
        {
          value: 'edit',
          label: <v-icon class="mr-2">{{ mdiPencil }}</v-icon>,
        },
        {
          value: 'delete',
          label: <v-icon class="mr-2">{{ mdiDelete }}</v-icon>,
        },
      ],
      loading: false,
      blockItems: [],
      totalItems: 10,
      selectedItem: null,
      dialogBlockSlotTime: false,
      dialogDeleteBlockSlotTime: false,
      initForm,
      formBlockTimeSlot: new Form(initForm),
      options: {},
      blockTimeSlotMeta: 0,
      showAllSlots: true,
      bookingList: [],
      openBookingList: false,
      showError: false,
      errorMessage: '',
    }
  },
  mounted() {
    this.fetchBlockTimeSlotList()
  },
  watch: {
    blockTimeSlots(newValue) {
      if (newValue) {
        this.blockItems = newValue
        this.totalItems = newValue.length
      }
    },
    blockTimeSlotListMeta(newValue) {
      if (newValue) {
        this.blockTimeSlotMeta = newValue
      }
    },
    formBlockTimeSlot: {
      handler(newValue) {
        this.formBlockTimeSlot = newValue
      },
      deep: true,
    },
    showAllSlots() {
      this.fetchBlockTimeSlotList()
    },
  },
  computed: {
    ...mapState({
      blockTimeSlots: (state) => state.bookable.blockTimeSlotList,
      blockTimeSlotListMeta: (state) => state.bookable.blockTimeSlotListMeta,
    }),
    submitApi() {
      return this.createBlockTimeSlot
    },
    timeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
  methods: {
    ...mapActions({
      createBlockTimeSlot: 'bookable/createBlockTimeSlot',
      getBlockTimeSlotList: 'bookable/getBlockTimeSlotList',
      updateBlockTimeSlotItem: 'bookable/updateBlockTimeSlotItem',
      deleteBlockTimeSlotItem: 'bookable/deleteBlockTimeSlotItem',
    }),
    createItem() {
      this.dialogBlockSlotTime = true
      this.selectedItem = null
      this.formBlockTimeSlot = new Form(this.initForm)
    },

    editItem(item) {
      this.dialogBlockSlotTime = true
      this.selectedItem = item
      const start = this.formatDateString(item.start_time)
      const end = this.formatDateString(item.end_time)
      this.formBlockTimeSlot = new Form({ ...item, start: start, end: end })
    },
    formatDateString(dateString) {
      const date = new Date(dateString)
      const isoString = date.toISOString()
      return isoString.replace(/\.\d{3}Z$/, '.000Z')
    },

    deleteItem(item) {
      console.log(item)
      this.dialogDeleteBlockSlotTime = true
      this.selectedItem = item
    },

    cancelDialog() {
      this.dialogBlockSlotTime = false
      this.selectedItem = null
    },
    cancelDialogDelete() {
      this.dialogDeleteBlockSlotTime = false
      this.selectedItem = null
    },
    convertToUTC(dateString) {
      const date = new Date(dateString)
      return date.toLocaleString('en-US', { timeZone: 'UTC' })
    },
    formatTime(time) {
      return timeOnlyFormat(time)
    },
    formatDate(date) {
      return dateOnlyFormat(date, DATETIME_FORMAT.eventDateFormat)
    },
    toggleShowHidePastSlots() {
      return (this.showAllSlots = !this.showAllSlots)
    },
    findMatchTime(itemBlock, field) {
      const findMatchItem = this.blockItems.find(
        (item) => item.id === itemBlock.id
      )
      if (findMatchItem) {
        return dateFormat(`${findMatchItem.date} ${findMatchItem[field]}`)
      }
    },
    async submitCreate() {
      const { id } = this.$route.params
      const { start, end, date } = this.formBlockTimeSlot
      const dateFormated = dateOnlyFormat(date, DATETIME_FORMAT.dateFormatYMD)

      const newForm = {
        id: this.formBlockTimeSlot.id,
        description: this.formBlockTimeSlot.description,
        bookable_id: id,
        start_time: this.formatDateString(
          changeDateInString(start, dateFormated)
        ),
        end_time: this.formatDateString(changeDateInString(end, dateFormated)),
        date: dateFormated,
      }

      this.loading = true

      try {
        if (this.formBlockTimeSlot.id)
          await this.updateBlockTimeSlotItem(newForm).then(() => {
            this.showSnackbar('Service & Space details successfully updated!')
          })
        else {
          await this.createBlockTimeSlot(newForm).then((data) => {
            if (data?.success) {
              this.showSnackbar('Service & Space successfully created!')
            } else {
              if (data?.data.list_booking) {
                const list = data.data.list_booking.map((booking) => {
                  return new Booking(booking)
                })
                this.bookingList = list
                this.errorMessage = data.message
                this.showError = true
              } else {
                this.showSnackbar(data?.message, false)
              }
            }
          })
        }
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogBlockSlotTime = false
        this.fetchBlockTimeSlotList()
      }
    },

    async fetchBlockTimeSlotList(options) {
      this.loading = true

      const currentPage = options?.page || 1

      try {
        await this.getBlockTimeSlotList({
          id: this.$route.params.id,
          page: currentPage,
          showAllSlotsType: this.showAllSlots ? 'exclude' : 'include',
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },

    async submitDeleteBlockTimeSlot() {
      const { id } = this.selectedItem
      this.loading = true
      try {
        await this.deleteBlockTimeSlotItem(id)
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogDeleteBlockSlotTime = false
        this.fetchBlockTimeSlotList()
      }
    },

    setSnackbarVisibility() {
      this.showError = !this.showError
      this.errorMessage = ''
      this.openBookingList = true
    },

    toggleBookingListModal() {
      this.openBookingList = !this.openBookingList
    },

    header() {
      return 'Booking List'
    },
  },
}
</script>

<style lang="scss" scoped>
.show-hide-slots-btn {
  display: flex;
  margin: 30px 0 0 auto;
}
</style>
